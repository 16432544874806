import { graphql } from "gatsby";
import Careers from "../../templates/careers";
import links from "/src/scripts/aboutLinks";

const CareersPage = ({
	data: {
		craft: { entry },
	},
}) => <Careers entry={entry} subLinks={links} />;

export const pageQuery = graphql`
	query CareersPage {
		craft {
			entry(slug: "careers") {
				... on CraftCMS_careers_careers_Entry {
					id
					heroTitle
					heroText
					availablePositions {
						... on CraftCMS_availablePositions_availablepositionsitem_BlockType {
							id
							availablePositionsItemTitle
							availablePositionsItemText
							availablePositionsItemButton {
								element {
									slug
									uri
								}
								url
								type
								text
								target
								title
								customText
							}
						}
					}
					contentImage {
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;
export default CareersPage;
